import React, { useState } from "react"

import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"
import TabTitle from "../../components/TabTitle"

import illustration2xDocumentation from "../../images/illustrations/illustration-administration-documentation@2x.png"
import illustration2xPlt from "../../images/illustrations/illustration-plt@2x.png"
import illustration2xFfp from "../../images/illustrations/illustration-ffp@2x.png"
import illustration2xCryo from "../../images/illustrations/illustration-cryo@2x.png"

import { theme } from "../../styles/theme"

const Rate = ({ location }) => {
  const [currentTab, setCurrentTab] = useState("RBC")

  const handleTabPress = pressed => {
    if (pressed === currentTab) {
      return
    }
    setCurrentTab(pressed)
  }

  return (
    <Layout>
      <SEO title="Rate and giving sets" />

      <CustomNavTitle
        title="Administration"
        destination="/administration/"
        location={location}
      />

      <section css={[theme.components.row, styles.reverseSidebar]}>
        <div>
          <article className="theme max-w-content">
            <h2 css={[theme.components.title, theme.components.titleRed]} className="mt-gap">
              Selection and use of administration sets:
            </h2>
            <ul className="spaced mt-gap-2">
              <li>
                CE or UKCA -marked blood transfusion set (170-200µm integral mesh
                filter).
              </li>
              <li>
                The administration set should be changed at least every 12 h (or
                per manufacturer’s instructions).
              </li>
              <li>
                Peripheral IV, central IV or intraosseous access suitable.
              </li>
              <li>
                Rapid infusion devices should be used when large volumes are
                required quickly. External pressure devices should only be used
                in an emergency situation via large‐gauge venous access.
              </li>
              <li>
                All devices must be certified for use with blood components and
                used in accordance with manufacturer's instructions.
              </li>
            </ul>
          </article>

          <section className="tabs">
            <ul css={styles.tabsButtons}>
              <TabTitle
                onPress={() => handleTabPress("RBC")}
                isActive={currentTab === "RBC"}
                title="RBC"
              />
              <TabTitle
                onPress={() => handleTabPress("PLT")}
                isActive={currentTab === "PLT"}
                title="PLT"
              />
              <TabTitle
                onPress={() => handleTabPress("FFP")}
                isActive={currentTab === "FFP"}
                title="FFP/CCP"
              />
              <TabTitle
                onPress={() => handleTabPress("CRYO")}
                isActive={currentTab === "CRYO"}
                title="CRYO"
              />
            </ul>

            <div className="tabs__content" css={styles.tabsContent}>
              {currentTab === "RBC" && (
                <article>
                  <div>
                    <section className="theme max-w-content">
                      <h3 className="tabs__content-title">Red Cells</h3>
                      <ul className="spaced mt-gap-2">
                        <li>
                          Red blood cells and plasma may be transfused using the
                          same administration set.
                        </li>

                        <li>Routine administration, 90–120 min per unit.</li>

                        <li>
                          Patients who are less tolerant of increased blood
                          volume should be transfused slowly but ensuring
                          completion within 4 h of removal from
                          temperature‐controlled storage. Careful haemodynamic
                          monitoring required.
                        </li>

                        <li>
                          Major haemorrhage: rapid transfusion may be
                          appropriate (use of a blood warmer recommended).
                        </li>

                        <li>
                          Do not administer any medications or fluids via the
                          same line or lumen (risk of haemolysis).
                        </li>

                        <li>
                          Not necessary to prime or flush sets with 0.9% saline.
                        </li>

                        <li>
                          A blood warmer should be used for patients with cold
                          agglutinins.
                        </li>
                      </ul>
                    </section>
                    <aside>
                      <img
                        src={illustration2xDocumentation}
                        alt="Illustration"
                      />
                    </aside>
                  </div>
                </article>
              )}

              {currentTab === "PLT" && (
                <article>
                  <div>
                    <section className="theme max-w-content">
                      <h3 className="tabs__content-title">Platelets</h3>
                      <ul className="spaced mt-gap-2">
                        <li>
                          A new administration set must be used exclusively for
                          platelets (risk of haemolysis).
                        </li>

                        <li>
                          Adult therapeutic dose (ATD) administered over 30-60
                          mins.
                        </li
                        >
                        <li>
                          <strong>DO NOT</strong> store in the fridge
                        </li>
                      </ul>
                    </section>
                    <aside>
                      <img src={illustration2xPlt} alt="Illustration" />
                    </aside>
                  </div>
                </article>
              )}

              {currentTab === "FFP" && (
                <article>
                  <div>
                    <section className="theme max-w-content">
                      <h3 className="tabs__content-title">
                        Fresh Frozen Plasma
                      </h3>
                      <ul className="spaced mt-gap-2">
                        <li>
                          Can be transfused via the same administration set as
                          red cells.
                        </li>

                        <li>
                          Routine administration: 10–20 mL/kg/h (complete within
                          4 hrs of removal from temp controlled storage).
                        </li>

                        <li>
                          Major haemorrhage: rapid transfusion of FFP (not CCP) may be appropriate.
                        </li>
                      </ul>
                    </section>
                    <aside>
                      <img src={illustration2xFfp} alt="Illustration" />
                    </aside>
                  </div>
                </article>
              )}

              {currentTab === "CRYO" && (
                <article>
                  <div>
                    <section className="theme max-w-content">
                      <h3 className="tabs__content-title">Cryoprecipitate</h3>
                      <ul className="spaced mt-gap-2">
                        <li>Typical adult dose is 2 x 5-unit pools.</li>

                        <li>Routine administration: 10–20 mL/kg/h.</li>

                        <li>
                          Complete within 4 hrs of removal from temp controlled
                          storage.
                        </li>
                      </ul>
                    </section>
                    <aside>
                      <img src={illustration2xCryo} alt="Illustration" />
                    </aside>
                  </div>
                </article>
              )}
            </div>
          </section>
        </div>
      </section>
    </Layout>
  )
}

const styles = {
  reverseSidebar: css({
    overflow: "hidden",
    "& > *": {
      display: "flex",
      flexWrap: "wrap",
      margin: "calc(3rem / 2 * -1)",
      "& > *": {
        margin: "calc(3rem / 2)",
        flexBasis: "460px",
        flexGrow: 1,
      },
      "& > :last-child": {
        flexBasis: 0,
        flexGrow: 999,
        minWidth: "calc(50% - 1rem)",
        marginBottom: theme.gap,
      },
    },
  }),
  tabsButtons: css({
    display: "flex",
    justifyContent: "flex-start",
    "& > *": {
      flex: "0 1 200px",
      marginBottom: 0,
      marginRight: "0.5rem",
    },
    "& > :last-child": {
      marginRight: 0,
    },
  }),
  tabsContent: css({
    backgroundColor: theme.colours.red,
    padding: theme.gapDouble,
    "& *, & .theme ul li::before": {
      color: theme.colours.white,
    },
    "& > article": {
      overflow: "hidden",
      "& > *": {
        display: "flex",
        flexWrap: "wrap",
        margin: "calc(3rem / 2 * -1)",
        "& > *": {
          margin: "calc(3rem / 2)",
          flexBasis: "400px",
          flexGrow: 1,
        },
        "& > :first-child": {
          flexBasis: 0,
          flexGrow: 999,
          minWidth: "calc(50% - 1rem)",
          marginBottom: theme.gap,
        },
      },
    },
  }),
}

export default Rate
